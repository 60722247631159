body {
    font: 100%/1.5 @font_stack;
    color: @primary_text;
    -webkit-font-smoothing: antialiased;
}

i,
em,
cite {
    font-style: italic;
}

b,
strong {
    font-weight: bold;
}

strike {
    text-decoration: line-through;
}

code,
pre {
    // font-family: @font_stack_monaco;
}

abbr,
acronym {
    font-size: 85%;
    letter-spacing: 0.1em; 
    text-transform: uppercase;
}

abbr[title],
acronym[title],
dfn[title] {
    // border-bottom: 1px dotted @primary_40;
    cursor: help;
}

del {
    text-decoration: line-through;
}

// Link Colours
a,
.condense_control {
    text-decoration: none;
    outline: 0;
    color: @primary_50;
    
    .content &{
        // border-bottom: 1px solid @primary_20;
        
        &:hover {
            border-bottom: 1px solid @primary_65;
        }

    }
}

.condense_control {
    white-space: nowrap;
}

// Headings
h1, h2, h3, h4, h5, h6 {
    color: @primary_base;
    font-size: 100%;
    font-weight: normal;
    
    &:first-child {
        margin-top: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
}

h1 {
    #type .core(@type_title, 2.5);
    margin: 0;
}

h2 {
    #type .core(@type_h2, 1.875);
    #type .margin-bottom(@type_h2, 0.75);
    
    .content & {
        #type .content-margin(@type_h2, -0.25);
    }
}

h3 {
    .sub-title();
}

h4 {
    #type .core(@type_h4, 1.25);
    color: @secondary_base;
    #type .margin-bottom(@type_h4, 0.75);
}

.content {
    #type .core(@type_body, 1.75);

    p {
        a {
            color: @accent_base;
        }
    }
    
    p,
    ul,
    ol,
    dl,
    img,
    address,
    iframe,
    blockquote,
    pre,
    hr,
    object,
    embed,
    iframe {
        display: block;
        #type .margin-top(@type_body, 1.2);
        #type .margin-bottom(@type_body, 1.2);
    }
    
    > *:first-child {
        margin-top: 0;
    }
    
    p {
        + p {
            #type .margin-top(@type_body, -0.25);
        }
    }
    
    img {
        max-width: 100%;
                
        &[align="left"] {
            clear: both;
            float: left;
            margin-right: @gutter_width * 4;
        }
        
        &[align="right"] {
            clear: both;
            float: right;
            margin-left: @gutter_width * 4;
        }
        
        &[align="center"],
        &[align="middle"] {
            clear: both;
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    /* List items */
    ul, ol {
        margin-left: @gutter_width * 3;

		a small {
			white-space: nowrap;
			color: @secondary_base;
		}
    }
    
    ul li {
        list-style: square;
    }
    
    ol li {
        list-style: decimal;
    }
    
    li {
        #type .margin-top(@type_body, 0.25);
        #type .margin-bottom(@type_body, 0.25);
    }
    
    ul ul,
    ul ol,
    ol ol,
    ol ul {
        #type .margin-top(@type_body, 0.25);
        #type .margin-bottom(@type_body, 0.25);
    }
    
    blockquote {
        #type .margin-top(@type_body, 1.5);
        #type .margin-bottom(@type_body, 1.5);
        font-size: @type_h2;
        text-indent: -0.35em;
        margin-left: 0;
        // padding-left: @gutter_width * 1.5;
        // border-left: @gutter_width solid @secondary_20;
        
/* This doesn't work for Less4J
        * {
            margin-right: 0;
        }
*/        
        img {
            max-width: 100%;
        }
    }
    &.content-small {
        #type .core(@type_caption, 1.5);
    }
    // The following is a fix for the bug in Less4J on line 186
    blockquote * {
        margin-right: 0;
    }
}

.sub-title() {
    #type .core(@type_caption, 1.25);
    text-transform: uppercase;
    letter-spacing: 2px;
    
    a {
        &:hover {
            border-bottom: 1px solid @primary_20;
        }
    }
}

.column-title {
  // .sub-title();
  text-align: center;
  #type .core(@type_h2, 1.25);
  #type .margin-bottom(@type_h2, 2);
  #type .margin-top(@type_h2, 2);
  font-family: @font_stack_secondary;
  color: @primary_80;
}

