html {
    font-family: 'mobile';
    
    /* IE6 */
    /* IE7 */
    /* IE8 */
    &.lt-ie9 {
        font-family: 'desktop';
    }
}

/* Reset your font families here!       
----------------------------------- */
body {
    //font-family: Arial, Helvetica, sans-serif;
}

/* Queries for supported browsers.       
----------------------------------- */
body:after {
    display: none;
}

/*@media screen and (min-width: 35em) {
    html {
        font-family: "skinny";
    }
    
    body:after {
        content: "skinny";
    }
}*/

@media screen and (min-width: 1251px) {
    html {
        font-family: "desktop";
    }
    
    body:after {
        content: "desktop";
    }
}

@media screen and (max-width: 1250px) {
    
    .wrapper,
    .full-width,
    #footer {
        .transform(translateY(0));
        @transition: 0.25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
        .transition-transform(@transition);
        .backface-visibility();
        
        .open-drawer & {
            
        }
    }
    .open-drawer {
        #header-drawer {
            visibility: visible;
        }
    }
    .wrapper {
        width: auto;
        padding-left: @gutter_width;
        padding-right: @gutter_width;
    }
    
    #main {
    }
    
    #footer {
        #type .core(14, 3);
    }
    
    #header-drawer {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        background: @pale-blue;
        padding: 10px;
        visibility: hidden;
        
        #nav-main {
            padding: 10px;
            overflow: hidden;
            
            ul {
                -webkit-column-count: 2;
                   -moz-column-count: 2;
                    -ms-column-count: 2;
                     -o-column-count: 2;
                        column-count: 2;
                
                -webkit-column-gap: 10px;
                   -moz-column-gap: 10px;
                    -ms-column-gap: 10px;
                     -o-column-gap: 10px;
                        column-gap: 10px;
                
                li {
                    display: block;
                    padding: 0 0 2px 0;
                    text-align: left;
                    margin-bottom: 5px;
                    
                    a {
                        
                    }
                }
                
            }
        }
        
        #nav-social {
            padding: 10px 10px 20px 10px;
        }
    }
    
    #header {
        // margin-bottom: @baseline_px;
        // border-bottom: 1px solid @secondary_base;
        padding-top: 0;
        padding: 0 0 @baseline_px;

        .wrapper {
            padding: (@baseline_px * 1.875) 0 (@baseline_px * 1.25) 0;

            .inner-wrapper {
                width: 90%;
                margin: 0 auto;
                background: #fff;
                padding-top: @gutter_width;
            }
        }
        .title {
            height: 24px;
            margin-top: 0;
            
            > a {
                display: none;
            }
            
            form.sub-form {
            	display: none;
            }
            
            .logo {
                margin: 0;
                @transform: scale(0.75);
                .transform(@transform);
                .transform-origin(0 0);
            }
        }
        .logo-subtitle {
            margin-top: @gutter_width;
            @transform: scale(0.85);
            .transform(@transform);
            .transform-origin(top left);
            text-align: left;
            width: 100%;
        }
        
        #menu-toggle {
            position: absolute;
            top: @gutter_width * 2.75;
            right: 5%;
            text-align: right;
            #type .core(20, 1.5);
            cursor: pointer;
            color: @primary_base;
            
            .pa,
            .str {
                // vertical-align: middle;
                // display: inline-block;
            }
            
            .pa {
                .s {
                    position: absolute;
                    top: 55%;
                    right: 8px;
                    margin-top: -8px;
                    width: 18px;
                    height: 16px;
                    background-position: -220px 0;
                }
            }
        }
    }
    
    .layout-sidebar,
    .layout-article {
        padding-left: 0;
        
        .widget-title {
            position: static;
            top: 0;
            left: 0;
            margin-left: @gutter_width;
            margin-right: @gutter_width;
        }
        &.layout-article-blog-post, &.layout-article-music-library {
            width: 100%;
        }
    }
	.layout-column .content {
		#grid .core .offsetL(0);
		#grid .core .offsetR(0);
	}

    .layout-sidebar {
        > .sidebar {
            float: none;
            width: auto;
            margin-left: auto;
            margin-bottom: @baseline_px * 1.5;
            margin-top: -@baseline_px * 0.625;
            
            .filter-nav {
                #type .core(@type_body, 1.5);
                text-transform: none;
                letter-spacing: 0;

                ul {
                    margin: 0 -(@gutter_width * 0.5);
                    
                    li {
                        display: inline-block;
                        margin: 0 (@gutter_width * 0.5);
                        
                        &.active {
                           	display: inline-block;

                            a {
                                .pa {
                                    display: none;
                                }
                                
                                &:hover {
                                    border-bottom: 1px solid @accent_40;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .layout-article {
        .hero {
            margin-bottom: @baseline_px;
        }
        
        .hero-empty {
			height: 20px;
        }
        
        .meta-sidebar {
            padding-right: 0;

            > .sidebar {
                float: none;
                width: auto;
                margin-right: auto;
                margin-bottom: @baseline_px * 2;
            }
            
            .meta {
                .group {
                    margin-bottom: @baseline_px;
                }
            }
            
            .social-sharing {
                margin: (@baseline_px * 1.75) 0 (@baseline_px * 1.5) 0;
            }
        }
        
        .content-buffer {
            padding: 0;
            width: auto !important;
            height: auto !important;
        }
        
        .faux-widget-title {
            float: none;
            margin-left: auto;
        }

        .detail {
            > .image {
                position: relative;
                transform: translateX(0);
                margin-top: 8px;
                margin-right: 16px;
                float: left;
            }
        }
        
        &.layout-article-artist {
            .hero {
                margin-left: auto;
            }

            .article-title {
                margin-left: auto;
                margin-right: auto;
                padding: 0;
                position: static;
                background: transparent;
                #type .margin-bottom(@type_title, 1);
            }
        }
        
        &.layout-article-release {
            > header {
                margin-left: auto;
                margin-bottom: @baseline_px * 1.5;
                
                .article-title,
                p {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            
            .core {
                padding-right: 0;
                
                > .media-sidebar {
                    float: none;
                    margin-right: auto;
                    margin-bottom: @baseline_px * 2;
                    width: auto;
                    
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    
    .widget {
        &.widget-featured {
            margin-left: 0;
            margin-right: 0;
            
            .feature-column {
                margin-left: 0;
                margin-right: 0;
                float: none;
                width: auto;
                
                .item {
                    margin-bottom: @baseline_px * 4;
                    
                    img {
                        width: 100%;
                        height: auto;
                    }
                    
                    + .item {
                        margin-top: auto;
                    }
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        
        &.widget-grid {
            
            .item {
                .detail {
                    .title {
                        text-transform: none;
                        letter-spacing: 0;
                    }
                    
                    .byline {
                        font-size: 14px;
                        line-height: 1;
                    }
                    
                    .caption {
                        font-size: 14px;
                        line-height: 1;
                    }
                }
            }
            
            &.widget-grid-social {
                .item {
                    padding-top: 33%;
                    margin-bottom: 0;
                    
                    .item-inner {
                        top: @gutter_width;
                        left: @gutter_width;
                        bottom: @gutter_width;
                        right: @gutter_width;
                        width: auto;
                        height: auto;
                    }
                    
                    img {
                        width: 75%;
                        top: 12.5%;
                        margin-top: -10px;
                    }
                }
                
                &.widget-grid-3 {
                    .item {
                        height: auto !important;
                    }
                }
            }
            
            &.widget-grid-3 {
                .item {
                    .box-sizing();
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: @gutter_width;
                    padding-right: @gutter_width;
                    width: 33.33%;
                }
                .blog-post .item {
                    width: 100%;
                }
            }
            
            &.widget-grid-9 {
                .item {
                    .box-sizing();
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: @gutter_width;
                    padding-right: @gutter_width;
                    width: 100%;
                }
            }
        }
        
        
        
        &.widget-icon-list {
            ul {
                max-width: 100%;
            }
        }
    }
    
    .social-buttons {
        overflow: hidden;
        margin-left: -@gutter_width;
        margin-right: -@gutter_width;
        
        .sidebar & .faux-widget-title {
            margin-top: @baseline_px;
            margin-bottom: @baseline_px;
            padding: 0 @gutter_width;
        }
        
        .group {
            .box-sizing();
            float: left;
            width: 50%;
            margin: 0 0 (@baseline_px * 1.5) 0;
            padding: 0 @gutter_width;
            min-height: @baseline_px * 3.75;
            
            &.group-subscribe {
                float: none;
                width: 100%;
            }
        }
    }
    
    .column-title {
      display: none;
    }
}


@media screen and (max-width: 550px) {
    #header {
        padding-bottom: 0;
        .title {
            .logo {
                margin: 0;
                margin-top: 3px;
                @transform: scale(0.4);
                .transform(@transform);
                .transform-origin(0 0);
            }
        }
        .logo-subtitle {
            margin-top: 0;
        }
        .wrapper {
            .inner-wrapper {
                padding-top: 0;
            }
        }
        #menu-toggle {
            top: @gutter_width * 2;
        }
        .slideshow-carousel-container {
            margin: (@baseline_px * 2) 0 @baseline_px 0;
        }
    }

	#main {
		padding-bottom: 80px;
        margin-left: 0;
        margin-right: 0;
	}

    .layout-article {
        .detail {
            > .image {
                position: relative;
                width: 150px;
                margin-top: 8px;
                margin-right: 16px;
                float: left;
            }
        }
    }

    #footer {
        margin-top: -@baseline_px * 4.5;
        height: auto;
        line-height: 2.25;
        
        .copyright {
            // display: block;
            margin: 0 auto;
            padding-top: 25px;
            #type .core(16, 1.5);
        }
        
        #nav-footer {
            display: block;
            #type .core(13, 2);
            padding-bottom: 13px;
            
            ul {
                li {
                    padding: 0 (@gutter_width / 2);
                }
            }
        }
    }
    
    .widget {
        &.widget-grid {
            
            &.widget-grid-social {
                .item {
                    padding-top: 100%;
                    
                    img {
                        top: 12.5%;
                    }
                }
            }
            
            &.widget-grid-3 {
                .item {
                    .box-sizing();
                    width: 100%;
                    height: auto !important;
                    
                    .media {
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    
    .social-buttons {
        overflow: visible;
        margin-left: auto;
        margin-right: auto;
        
        .sidebar & .faux-widget-title {
            padding: 0;
        }
        
        .group {
            float: none;
            width: 100%;
            padding: 0;
            min-height: 0;
        }
    }
}