/* Flickity fullscreen v1.0.1
------------------------- */

.flickity-enabled.is-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.9);
  padding-bottom: 35px;
  z-index: 1;

  .flickity-slider {
    // height: auto;

    .slideshow-item {
      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

}

.flickity-enabled.is-fullscreen .flickity-page-dots {
  display: none;
}


/* prevent page scrolling when flickity is fullscreen */
html.is-flickity-fullscreen {
  overflow: hidden;
}

/* ---- flickity-fullscreen-button ---- */

.flickity-fullscreen-button {
  display: block;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

/* right-to-left */
.flickity-rtl .flickity-fullscreen-button {
  right: auto;
  left: 10px;
}

.flickity-fullscreen-button-exit { display: none; }

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit { display: block; }
.flickity-enabled.is-fullscreen .flickity-fullscreen-button-view { display: none; }

.flickity-fullscreen-button .flickity-button-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px;
}
