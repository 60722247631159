.social-nav ul {
	list-style-type: none;
}

.social-nav ul {
    @white: #fff;
    
    display: inline-block;
    margin: 0 @gutter_width 0 0;
    
    .box a:hover {
        text-decoration: none;
    }
    
    li {
    	list-style-type: none;
    	float: left;
    }
    
    .social-share {
        display: block;
        height: 30px;
        width: 70px;
        margin-right: 8px;
        float: left;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        text-decoration: none;
        .border-radius(4px);
        border: 0;
        text-decoration: none;
        color: @white;
        border-bottom: none;
    }
    
    .twitter .social-share {
       	background: #26c3eb;
	 }
	    
	    .facebook .social-share {
	       background: #5582c9;
	    }
	    
	    .googleplus .social-share {
	       background: #6d6d6d;
	    }
	    
	    .pinterest .social-share {
		    background: #C9232D;
	    }
    
   
}