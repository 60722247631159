.detail { // nested to increase specificity enough to override default Mailchimp styles :(
	#mc_embed_signup {
		font-family: inherit;
		form {
			padding: 0;
			.mc-form-label {
				font-size: 1.5em;
			}
		}
		.button {
			font-size: 1.35em;
			height: 40px;
			line-height: 40px;
		}
		.mc-field-group {
			padding-bottom: 0;
			input {
				padding: 11px 0;
				margin-top: 10px;
			}
		}

		#mce-responses {
			margin: 0;
			padding: 0;
			width: auto;
			.response {
				margin: 0;
				padding: 20px;
				width: auto;
				&#mce-error-response {
					background: lightGray;
					border-radius: 5px;
				}
				&#mce-success-response {
					background: #529214;
					border-radius: 5px;
					color: white;
				}
			}
		}
	}
}
