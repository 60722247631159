// Sprites
@sprite:                    "../img/sprite.png?20160520";
@sprite_2x:                 "../img/sprite-2x.png?20160520";
@sprite-w:					960px;
@sprite-h:					80px;
@blank:                     ~"url('data:image/gif;base64,R0lGODlhAQABAHAAACH5BAUHAAAALAAAAAABAAEAAAICRAEAOw==')";


// Colours
@primary_base:              #4B4338;
@bg_base:                   #FFFFFF;
@secondary_base:            #223344;
@accent_base:               #4C87AD;
@light-grey:                #AABBCC;
@pale-blue:                 #F0F4F8;

@primary_80:                mix(@primary_base, @bg_base, 80%);
@primary_75:                mix(@primary_base, @bg_base, 75%);
@primary_65:                mix(@primary_base, @bg_base, 50%);
@primary_50:                mix(@primary_base, @bg_base, 65%);
@primary_40:                mix(@primary_base, @bg_base, 40%);
@primary_20:                mix(@primary_base, @bg_base, 20%);

@secondary_65:              mix(@secondary_base, @bg_base, 65%);
@secondary_40:              mix(@secondary_base, @bg_base, 40%);
@secondary_50:              mix(@secondary_base, @bg_base, 50%);
@secondary_20:              mix(@secondary_base, @bg_base, 20%);

@accent_65:                 mix(@accent_base, @bg_base, 65%);
@accent_40:                 mix(@accent_base, @bg_base, 40%);
@accent_20:                 mix(@accent_base, @bg_base, 20%);

@primary_text:              @primary_80;


// Layout
@footer_height:             @baseline_px * 3;

@col_width:                 70px;
@gutter_width:              20px;


// Type Stacks
@font_stack:                'Fira Sans', sans-serif;
@font_stack_secondary:      'Pitch Sans', sans-serif;
@baseline:                  16;
@baseline_px:               @baseline * 1px;

@type_h1:                   32;
@type_h2:                   22;
@type_h3:                   18;
@type_h4:                   16;
@type_h5:                   14;
@type_h6:                   11;
@type_body:                 18;
@type_caption:              16;
@type_meta:                 13;
@type_tiny:                 11;

@type_title:                @type_h1;



.s {
    background: url(@sprite) 0 0 no-repeat;
    // background: rgba(0, 0, 0, 0.10);
}

// http://css-tricks.com/snippets/css/retina-display-media-query/
@media (-webkit-min-device-pixel-ratio: 1.2), (min-resolution: 120dpi) { 
    .s {
        background-image: url(@sprite_2x) !important;
        -webkit-background-size: @sprite-w @sprite-h;
           -moz-background-size: @sprite-w @sprite-h;
             -o-background-size: @sprite-w @sprite-h;
                background-size: @sprite-w @sprite-h;
    }
}

/* Positioning anchor */
.pa {
    display: inline;
    position: relative;
}

// Grid Spans
// #grid > .core(@col_width, @gutter_width, 6);

@easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);