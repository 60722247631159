.layout-sidebar {
	.inner-wrapper,
	.widget-grid {
		position: relative;
	}
	.inner-wrapper {
		margin-left: -20px;
		margin-right: -20px;
		.slideshow-container {
			margin-left: 20px;			
			margin-right: 20px;			
		}
	}
	.live-images-carousel {
		padding-top: 50%;
		margin-bottom: 85px;
		margin-top: 20px;
	}
	.grid-wrapper {
		margin-top: 20px;
	}
}

.section-title {
	position: absolute;
	left: 0;
	top: 0;
	text-transform: uppercase;
	transform: translateX(~"calc(-100% - 20px)");
	@media screen and (max-width: 1250px) {
		position: relative;
		transform: translateX(0);
		margin-left: 20px;
	}
}