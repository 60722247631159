input,
textarea,
select {
    display: block;
    .box-sizing();
    margin: (@baseline_px * 0.25) 0;
    color: @primary_base;
    font-family: @font_stack;
    font-size: @type_caption;
    line-height: @baseline_px;
}

input,
textarea {
    width: 100%;
    background: #fff;
    border: 1px solid @secondary_base;
    padding: 3px;
    -webkit-appearance: none;
    
    &:focus {
        border-color: mix(@primary_base, @secondary_base, 50%);
    }
    
    &::-webkit-input-placeholder {
        color: @primary_40;
    }
    
    &:-moz-placeholder {
        color: @primary_40;
    }
    
    &::-moz-placeholder {
        color: @primary_40;
    }
    
    &:-ms-input-placeholder {
       color: @primary_40;
    }
}

input[type="submit"] {
    padding-left: 8px;
    padding-right: 8px;
    width: auto;
    border: 0;
    background: @secondary_base;
    color: #fff;
    font-size: @type_meta * 1px;
}