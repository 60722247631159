.slideshow-carousel-container {
	margin: (@baseline_px * 4) 0 (@baseline_px * 2) 0;
	height: auto;
	position: relative;
	overflow-y: hidden;
	padding-top: 34%;
	display: flex;

	&.flickity-enabled {
		overflow-y: visible;
	}

	.flickity-viewport {
		position: absolute;
		top:0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
	}
	.slideshow-item {
		display: block;
		width: 100%;
		height: 100%;
		flex-shrink: 0;
		position: absolute;
		left: 0;
		top: 0;

		& img {
			width: 100%;
			height: auto;
		}
	}

	&.is-fullscreen {
		margin-top: 0;
		padding-top: 0;
		z-index: 2;
		.flickity-slider {
			// display: flex;
			align-items: center;
			justify-content: center;
			.slideshow-item {
				top: 50vh;
				transform: translateY(-50%);
				img {
					display: block;
					max-height: 100%;
				}
			}
		}
	}
}
