.box-sizing(@declaration: border-box) {
    -webkit-box-sizing: @declaration;
       -moz-box-sizing: @declaration;
        -ms-box-sizing: @declaration;
            box-sizing: @declaration;
}

.transition(@transition) {
    -webkit-transition: @transition;
       -moz-transition: @transition;
        -ms-transition: @transition;
         -o-transition: @transition;
            transition: @transition;
}

.transition-transform(@arguments) {
    -webkit-transition: -webkit-transform @arguments;
       -moz-transition: -moz-transform @arguments;
        -ms-transition: -ms-transform @arguments;
         -o-transition: -o-transform @arguments;
            transition: transform @arguments;
}

.transform(@transformation) {
    -webkit-transform: @transformation;
       -moz-transform: @transformation;
        -ms-transform: @transformation;
         -o-transform: @transformation;
            transform: @transformation;
}

.transform-origin(@origin) {
    -webkit-transform-origin: @origin;
       -moz-transform-origin: @origin;
        -ms-transform-origin: @origin;
         -o-transform-origin: @origin;
            transform-origin: @origin;
}

.backface-visibility(@argument: hidden) {
    -webkit-backface-visibility: @argument;
       -moz-backface-visibility: @argument;
        -ms-backface-visibility: @argument;
         -o-backface-visibility: @argument;
            backface-visibility: @argument;
}

.background-clip() {
    -webkit-background-clip: padding-box;
       -moz-background-clip: padding;
            background-clip: padding-box;
}

.border-radius(@radius: 5px) {    
    -webkit-border-radius: @radius;
       -moz-border-radius: @radius;
            border-radius: @radius;
    
    .background-clip;
}

.no-select() {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

.opacity(@opacity) {
    opacity: @opacity / 100;
    filter: ~"alpha(opacity=@{opacity})";
}

.ellipsis(@declaration: ellipsis) {
    -ms-text-overflow: @declaration;
     -o-text-overflow: @declaration;
        text-overflow: @declaration;
    
    white-space: nowrap;
    overflow: hidden;
}

.no-tap-highlight() {
    -webkit-tap-highlight-color: transparent;
}

.font-face(@family-name, @path, @file, @weight: normal, @style: normal) {
	@font-face {
		font-family: @family-name;
		src: url("@{path}@{file}.eot");
		src: url("@{path}@{file}.eot?#iefix") format("embedded-opentype"),
			url("@{path}@{file}.woff2") format("woff2"),
			url("@{path}@{file}.woff") format("woff");
		font-weight: @weight;
		font-style: @style;
		font-stretch: normal;
	}
}

#type {
    .core(@font_size, @baseline_multiple, @bl: @baseline) {
        font-size: @font_size * 1px;
        line-height: @bl * @baseline_multiple / @font_size;
    }
    
    .margin-top(@font_size, @baseline_multiple, @bl: @baseline) {
        margin-top: @bl * @baseline_multiple / @font_size * 1em;
    }
    
    .margin-bottom(@font_size, @baseline_multiple, @bl: @baseline) {
        margin-bottom: @bl * @baseline_multiple / @font_size * 1em;
    }
    
    .content-margin(@font_size, @baseline_multiple) {
        &+ p,
        &+ ul,
        &+ ol,
        &+ dl,
        &+ img,
        &+ address,
        &+ iframe,
        &+ blockquote,
        &+ pre,
        &+ hr,
        &+ object,
        &+ embed,
        &+ iframe {
            // margin-top: @props;
            #type .margin-top(@font_size, @baseline_multiple);
        }
    }
}

// Grid stolen and hacked from Bootstrap
#grid {
    .core {

        .spanW(@columns) {
            width: @col_width * @columns;
        }
        
        .spanWGutter(@columns, @gutter_size: 2) {
            width: (@col_width * @columns) - (@gutter_width * @gutter_size);
        }
        
        .offsetL(@columns) {
            margin-left: @col_width * @columns;
        }
        
        .offsetR(@columns) {
            margin-right: @col_width * @columns;
        }
        
        .offsetLN(@columns) {
            margin-left: -(@col_width * @columns);
        }
        
        .offsetLNGutter(@columns, @gutter_size: 2) {
            margin-left: -(@col_width * @columns) + (@gutter_width * @gutter_size);
        }
        
        .offsetRN(@columns) {
            margin-right: -(@col_width * @columns);
        }
        
        .indentL(@columns) {
            padding-left: @col_width * @columns;
        }
        
        .indentR(@columns) {
            padding-right: @col_width * @columns;
        }
        
        // generate .spanX and .offsetX
        //.spanX(@num_columns); // Fix this when
    }
}