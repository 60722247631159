@import "include/mixins";
@import "include/normalize";
@import "include/shared";
@import (less) "colorbox.css";
@import "include/flickity.less";
@import "include/flickity-fullscreen.less";
@import "include/boilerplate";
@import "include/forms";
@import "include/fonts";
@import "include/typography";
@import "include/newsletter-form";
@import "include/section-title";
@import "include/share";
@import "include/component-animations";
@import "include/bootstrap-tooltip";
@import "include/carousel";

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

html,
body {
    padding: 0;
    margin: 0;
    background: @bg_base;
    height: 100%;
}

body {
    cursor: default;
}

#page {
    min-height: 100%;
}

.wrapper, .full-width {
   position: relative;
    z-index: 1;
    margin: 0 auto;
    // background: #fff;
    // background: #fff;
}

.wrapper {
	#grid > .core > .spanW(18);
}

.full-width {
	margin-top: -1 * @baseline_px;
	padding-top: @baseline_px;

	img {
		width: 100%;
		height: auto;
		display: block;
		margin-bottom: @baseline_px * 2;
	}
}

#main {
    margin: (@gutter_width * 2) @gutter_width 0 @gutter_width;
    padding-bottom: @footer_height * 2;

    .blog-post-title {
        font-weight: 500;

        a {
            color: @primary_base;
            &:hover {
                border-bottom: 2px solid @primary_20;
            }
        }
    }
}

#header {
    position: relative;
    margin: 0 auto;
    padding: @baseline_px 0 (@baseline_px * 3.5);
    background: #fff;

    .wrapper {
        padding: 0 @gutter_width;
        box-sizing: border-box;
        background: #fff;
    }
    
    .title {
        margin: 0;
        margin-top: @gutter_width;
        
        > a {
            position: absolute;
            top: @baseline_px * 3;
            left: 0;
            margin: 0;
			font-size: 18px;
			outline-style: initial;
			color: rgb(34, 51, 68);
			color: rgb(89, 102, 115);
			#type .core(18, 1.2);
			top: 52px;
			border-bottom: 1px solid rgb(211, 214, 218);
			
			&:hover {
				border-bottom-color: rgb(111, 122, 133);
			}
        }
        
        .logo {
        	position: relative;
            display: block;
            margin: 0 auto 0 auto;
            width: 503px;
            height: 45px;
            overflow: hidden;
			background-image: url("../img/rhian-logo.svg");
			background-size: contain;
			background-repeat: no-repeat; 

            a {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    .logo-subtitle {
        text-align: center;
        color: @primary_80;
        font-family: @font_stack_secondary;
        letter-spacing: 11px;
        margin-top: 8px;
    }
    
	.show-trigger .sub-form{
		display: none;
	}
	
	.show-form .sub-trigger{
		display: none;
	}
	
	form.sub-form {
		margin: 0;
		width: 33%;
		font-size: 16px;
		display: block;
		position: absolute;
	}
	
	input.sub-email {
		width: 70%;
		display: inline-block;
		outline: none;
		line-height: 20px;
		padding: 6px;
		
		&:focus {
			border-color: rgb(211, 214, 218);
		}
	}
	
	input.sub-button {
		margin-left: 2px;
		line-height: 32px;
		padding: 0px 12px;
		display: inline-block;
	}
	
	input.sub-email,
	input.sub-button {
		border-radius: 2px;
		font-size: 1em;
		height: 32px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
    

    
    #nav-social {
        width: 100%;
        position: relative;
        margin-top: @baseline_px;
        margin-bottom: 1px;
        text-align: right;
        display: flex;
        align-items: flex-end;
        flex-direction: row-reverse;
        // transform: scale(0.75, 0.75); /* Scale each icon li properly once socials have been finalised*/
        transform-origin: top right;

        ul {
            // float: right;  
            display: inline-block; 
        }
    }
}

#footer {
    position: relative;
    margin-top: -@footer_height; 
    height: @footer_height;
    width: 100%;
    background: @pale-blue;
    text-align: center;
    #type .core(@type_caption, 2);
    
    .copyright {
        display: inline-flex;
        padding: 9px @gutter_width 0 @gutter_width;
        color: @secondary_base;

        > div:first-child {
            margin-top: 2px;
        }
    }
    
    #nav-footer {
        display: inline-block;
        padding: 9px @gutter_width 0 @gutter_width;
        transform: translateY(-2px);
        
        ul {
            margin: 0;
            
            li {
                display: inline-block;

                a {
                    &:hover {
                        border-bottom: 1px solid mix(@primary_base, @pale-blue, 20%);
                    }
                }
            }
        }
    }
}

#nav-main {
    margin-top: @baseline_px * 2;
    padding: (@baseline_px * 0.5) 0 0 0;
    #type .core(18, 1.5);
    
    ul {
        margin: 0;
        text-align: center;
        
        li {
            display: inline-block;
            padding: 0 (@gutter_width * 0.95) - 2; /* Minus inline whitespace */
            font-family: @font_stack_secondary;
          
            a {
                color: @primary_base;
                font-weight: 500;
                
                &:hover {
                    border-bottom: 3px solid @primary_20;
                }
            }

            &.active {
                a {
                    border-bottom: 3px solid @primary_40;
                }
            }
        }
    }
}


#nav-social {
    ul {
        margin: 3px 0 0 0;
        padding: 0;
        height: 26px;
        
        li {
            display: inline-block;
            width: 40px;
            height: 40px;
            padding: 0 6px;
            
            a {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.loop-badge {
    z-index: 1;
    position: absolute;
    top: 0;
    left: @gutter_width;
    width: 45px;
    height: 30px;
    background-position: -160px 0;
}

.layout-sidebar,
.layout-article {
    #grid .core .indentL(3);
    
    .widget-title {
        position: absolute;
        top: 0;
        left: 0;
        #grid .core .offsetLNGutter(3, 1);
    }

    .page-description-container {
        // #grid .core .spanW(12);
    }
}

.layout-column .content {
	#grid .core .offsetL(3);
	#grid .core .offsetR(3);
	
	form {
		input[type=email] {
			width: 80%;
			float: left;
			padding-top: 12px;
			margin-right: 0.5em;
		}

		input[type=submit] {
			position: relative;
			top: 4px;
			font-size: 24px;
			line-height: 1;
			padding: 8px 16px;
		}
	}
}

.layout-sidebar {
    > .sidebar {
        float: left;
        #grid .core .spanWGutter(3);
        #grid .core .offsetLN(3);

		&.sidebar-narrow {
			> * { padding-right: @baseline_px * 2; }
		}

        .filter-nav {
            .sub-title();
            
            ul {
                margin: 0;
                
                li {
                    margin: (@baseline_px * 1/2) 0;
                    
                    a {
                        position: relative;
                        
                        &:hover {
                            border-bottom: 1px solid @primary_20;
                        }
                    }
                    
                    &.active {
	                	display: block;

						a {
		                	display: block;
                            color: @accent_base;
                            
                            .pa {
                                .s {
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    width: 6px;
                                    height: 11px;
                                    margin-top: -5px;
                                    background-position: -210px 0;
                                }
                            }
                            
                            &:hover {
                                border: 0;
                                
                                .str {
                                    border-bottom: 1px solid @accent_40;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    > .layout-article {
        padding-left: 0;
    }
}

.layout-article {
    position: relative;
    
    .hero {
        position: relative;
        margin: 0 0 (@baseline_px * 1.25) 0;
        
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .media {
        position: relative;
	}

	.hero, .media {
		.frame {
			position: absolute;
			display: block;
			border: 1px solid rgba(34,51,68,0.2);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	.hero-empty {
		height: 50px;
	}
    
    .article-title {
        #type .margin-top(@type_title, 1);
        #type .margin-bottom(@type_title, 1);
    }
    
    .faux-widget-title {
        .sub-title();
        float: left;
        // font-weight: 400;
        margin: 0;
        #grid .core .offsetLN(3);
        margin-top: 3px;
        // transform: translateX(~"calc(-100% - 25px)");
    }

	.media-sidebar {
		width: 300px;

		.media, .embed {
			max-width: 100%;
		}
		
		.embed iframe {
			border: none;
		}
	}
    
    .media-sidebar + .detail {
        // Selector Power!
        // Queue Tim 'the Tool Man' Taylor Grunt
        .content-buffer > *:first-child {
            margin-top: 0;
        }
    }

	.meta-title {
        .sub-title();
        #type .core(@type_caption, 1.75);
        #type .margin-bottom(@type_caption, 0);
	}

    .meta-sidebar {
        #grid .core .indentR(3);

        > .sidebar {
            float: right;
            width: 180px;
            #grid .core .offsetRN(3);
        }
        
        .meta {
            .term {
                .sub-title();
                #type .core(@type_caption, 1.75);
                #type .margin-bottom(@type_caption, 0);
            }
            
            .definition {
                display: block;
                #type .core(@type_caption, 1.5);
                
                &.single-link {
                    .ellipsis();
                }
            }
            
            .group {
                margin-bottom: @baseline_px * 1.75;
                word-wrap: break-word;
            }
        }
        .content-buffer > *:first-child {
            margin-top: 0;
        }
        
        .social-sharing {
            text-align: left;
        }
    }
    
    > .detail {
        // overflow: hidden;
        margin-bottom: @baseline_px * 3;
    }

    .detail {
        position: relative;
        .blog-post {

            .image {
                #type .margin-top(@type_body, 2);
                #type .margin-bottom(@type_body, 2);

                .image-caption {
                    #type .core(@type_caption, 1.2);
                    #type .margin-top(@type_caption, 0);
                    color: @secondary_50;
                }

                img {
                    #type .margin-top(@type_caption, 0.5);
                    #type .margin-bottom(@type_caption, 0.5);
                
                }
                
            }

        }

        > .image {
            position: absolute;
            left: 0;
            width: 190px;
            transform: translateX(~"calc(-100% - 20px)");
            margin-top: 35px;
            > .img {
                width: 100%;
                height: auto;
                margin: 0;
            }
        }
    }

    .detail + .social-nav {
        margin-top: @baseline_px * 2;
    }
    
    .detail .condense .condense_control_less {
            display: none;
    }
    
    .content-buffer {
        // margin-top: calc(22 * -1.25px);
        padding-right: @gutter_width * 2;
        overflow-wrap: break-word;
    }
    
    &.layout-article-blog-post, &.layout-article-music-library {
        #grid .core .indentL(0);
        #grid .core .spanW(12);
        margin-left: auto;
        margin-right: auto;
    }

    &.layout-article-artist {
        .hero {
            #grid .core .offsetLN(3);
            
            .loop-badge {
                left: auto;
                right: @gutter_width;
            }
        }
        
        .article-title {
            #grid .core .offsetLN(3);
            #grid .core .offsetR(3);
            margin-top: 0;
            margin-bottom: 0;
            padding: 0 (@gutter_width * 1.25) (@baseline_px * 1/2) 0;
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
        }
    }
    &.layout-article-release {
        
        > header {
            #grid .core .offsetLN(3);
            margin-bottom: @baseline_px;
            
            .article-title {
                display: inline-block;
                
                + p {
                    display: inline-block;
                    padding: 0 2px;
                    #type .core(@type_h3, 1.25);
                    color: @secondary_base;
                    
                    a {
                        color: @secondary_base;
                        border-bottom: 1px solid @secondary_40;
                        
                        &:hover {
                            border-bottom-color: @secondary_65;
                        }
                    }
                }
            }
        }
        
        .core {
            #grid .core .indentR(4);
            margin-bottom: @baseline_px * 4;
            
            > .media-sidebar {
                float: right;
                #grid .core .offsetRN(4);
                
                .media {
                    margin-bottom: @baseline_px * 1.25;
                }
            }
        }
        
        .widget-icon-list { margin-bottom: 0; }
    }
}

.widget {
    position: relative;
    margin: 0 -@gutter_width (@baseline_px * 4) -@gutter_width;
    
    + .widget {
        margin-top: @baseline_px * -3;
    }

	&.widget-social {
		margin-bottom: @baseline_px * 6;
	}

	&.widget-social + .widget-social {
		margin-top: @baseline_px * -4;
	}

    .widget-title {
    	padding-top: 2px;
   
        .sub-title();
    }
    
    .media {
        // background: @pale-blue;
    }

    .content-buffer {
        margin-left: @gutter_width;
        margin-right: @gutter_width;
    }

    // Featured
    &.widget-featured {
        overflow: hidden;

        .feature-column {
            float: left;
            margin: 0 @gutter_width;
            #grid .core .spanWGutter(6);

            .item {
                margin: 0;
                margin-bottom: 50px;

                .detail {

                    .feature-title {
                        font-weight: 700;
                    }

                    &.content.content-small {
                        p {
                            margin-bottom: 5px;
                        }
                    }
                }

                .media {
                    margin: 0 0 (@baseline_px * 0.875) 0;

                    img + img {
                        margin-top: @baseline_px * 0.875;
                    }

                    .lazyload-container + .lazyload-container {
                        margin-top: @baseline_px * 0.875;
                    }

                    img {
                        display: block;
                        max-width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        height: auto;
                    }
                }

                + .item {
                    margin-top: @baseline_px;
                }
            }

            .meta {
                margin: (@baseline_px / 2) 0;
                color: @secondary_50;
                #type .core(@type_meta, 1);
            }
        }
    }
    
    
    // Grid
    &.single-grid {
        margin-bottom: 0;
    }

    &.widget-grid {
        
        .grid-wrapper {
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
        }
        
        .grid-control-wrapper {
            margin: -@baseline_px @gutter_width @baseline_px @gutter_width;
            #type .core(@type_h5, 1);
            .transition(height 0.5s ease-out);
            
            .toggle {
                color: @secondary_base;
                cursor: pointer;

                &:hover {
                    border-bottom: 1px solid @secondary_40;
                }
            }
        }
    
        .item {
            position: relative;
            // float: left;
            margin: 0 @gutter_width (@gutter_width * 2) @gutter_width;
        
            .media {
                overflow: hidden;
                position: relative;
                width: 100%;
                margin: 0 0 (@baseline_px * 0.5) 0;
                
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
        
            .detail {
                .title {
                    #type .margin-bottom(@type_caption, 0.375);
                    #type .core(@type_body, 1.5, @baseline);
                    font-weight: 500;
                    text-transform: initial;
                    letter-spacing: initial;

                    & a {
                        color: @primary_80;
                    }
                    
                    span, .artist {
                        text-transform: none;
                        letter-spacing: 0;
                        color: @secondary_base;
                    }
                }
            
                .byline {
                    #type .core(@type_caption, 1.25);
                    #type .margin-top(@type_caption, 0.375);
                    #type .margin-bottom(@type_caption, 0.5);
                    color: @primary_text;
                }
            
                .caption {
                    #type .core(@type_caption, 1.2);
                    #type .margin-top(@type_caption, 0.5);
                    color: @primary_50;
                }


				.meta {
                    #type .core(@type_caption, 1.3);
                    #type .margin-top(@type_caption, 0.25);
                    color: @secondary_50;

                    .meta-more a {
                        text-transform: uppercase;
                        color: @primary_base;
                        &:hover {
                            border-bottom: 1px solid @primary_20;
                        }
                    }
                }
            }
        
            &.hover {
                .detail {
                    .title {
                        a {
                            border-bottom: 1px solid @primary_20;
                        }
                    }
                }
            }
        }
        
        &.widget-grid-social {
            
            .item {
                .box-sizing();
                margin-bottom: 20px;
                
                .item-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    border: 1px solid @secondary_base;
                }
                
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                
                img {
                    display: block;
                    position: absolute;
                    width: 80%;
                    height: auto;
                    top: 10%;
                    left: 10%;
                    margin: -10px 0 0 0;
                }
                
                .title {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: 0;
                    line-height: 40px;
                    .ellipsis();
                }
            }
            
            &.widget-grid-3 {
                .item {
                    height: (@col_width * 3) - (@gutter_width * 2) !important;
                }
            }
            
            &.widget-grid-video {
                .item {
                    height: ((@col_width * 3) - (@gutter_width * 2)) / 16 * 9 !important;
                }
            }
            
        }
    
        &.widget-grid-3 {
            .item {
                #grid .core .spanWGutter(4);
            }
        }
        
        &.widget-grid-9 {
            .item {
                #grid .core .spanWGutter(9);
            }
        }
        
        &.widget-grid-12 {
            .item {
                #grid .core .spanWGutter(12);
            }
        }
        
        &.widget-grid-16 {
            .item {
                #grid .core .spanWGutter(16);
            }
        }
    }

    
    
    // Link List
    &.widget-icon-list {
        margin-top: @baseline_px * 3;
        padding-top: 2px;

		h2.widget-title { margin-top: 10px; }

		ul {
            margin: 0;
            max-width: (@col_width * 6) - (@gutter_width * 2);
            
            li {
                .box-sizing();
                margin: 0 0 (@baseline_px * 0.5) 0;
                padding: 0 10px;
                width: 50%;
                float: left;
                list-style: none;
                line-height: 40px;
                .ellipsis();
                
                .service-icon {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -20px;
                }
                
                .str {
					margin-left: 50px;
                }
                
                a {
                    &:hover, &:active {
                        .str {
                            border-bottom: 1px solid @primary_20;
                        }
                    }
                }
            }
        }
    }

    // Link List
    &.widget-generic {
		.content {
			position: relative;
			top: -(@baseline_px / 4);
			margin-left: @gutter_width;
			margin-right: @gutter_width;
		}
	}
}

.widget-share {
    margin-top: @baseline_px * 2;
    margin-left: 0;
    margin-right: 0;

	#download-sharing & {
		margin-top: @baseline_px;
		margin-bottom: @baseline_px;
	}
}

.sidebar .widget-share { margin-bottom: 0; }

.service-icon {
    display: block;
    width: 40px;
    height: 40px;
    background-position: -999px -999px;
    
    .service-bandcamp & { background-position: 0 -40px; }
    .service-itunes & { background-position: -40px -40px; }
    .service-rdio & { background-position: -80px -40px; }
    .service-loop & { background-position: -120px -40px; }
    .service-spotify & { background-position: -160px -40px; }
    .service-amazon & { background-position: -200px -40px; }
    .service-soundcloud & { background-position: -240px -40px; }
    .service-youtube & { background-position: -280px -40px; }
    .service-facebook & { background-position: -320px -40px; }
    .service-tumblr & { background-position: -360px -40px; }
    .service-twitter & { background-position: -400px -40px; }
    .service-vimeo & { background-position: -440px -40px; }
    .service-lastfm & { background-position: -480px -40px; }
    .service-xbox & { background-position: -520px -40px; }
    .service-emusic & { background-position: -560px -40px; }
    .service-rhapsody & { background-position: -600px -40px; }
    .service-beatport & { background-position: -640px -40px; }
    .service-junodownload & { background-position: -680px -40px; }
	.service-deezer & { background-position: -720px -40px; }
	.service-myspace & { background-position: -760px -40px; }
	.service-googleplay & { background-position: -800px -40px; }
	.service-baboom & { background-position: -840px -40px; }
	.service-applemusic & { background-position: -880px -40px; }
	.service-instagram & { background-position: -920px -40px; }
	.service-spotifypl & { background-position: -880px 0; }
	.service-mixcloud & { background-position: -920px 0; }
}

.social-buttons {
    .group {
        margin: 0 0 (@baseline_px * 1.5) 0;
        
        h4 {
            margin: 0;
        }

        .button {
            margin: (@baseline_px * 0.5) 0 0 0;
            min-height: 21px;
            
            img,
            iframe {
                display: block;
            }
        }
    }
    
    .sidebar & {
        .faux-widget-title {
            float: none;
            position: relative;
            top: auto;
            left: auto;
            margin: 0 0 (@baseline_px * 2) 0;
        }
    }
}

.linked-content {
	padding-top: 1px;
}

time.timeago-pending { visibility: hidden; }
time.timeago-complete { visibility: visible; }

.media .lazyload-container {
	background: #F1F5F8 url("../img/media-placeholder.svg") 50% 50% no-repeat;
    background-size: 50px;
	position: relative;

	a, .frame {
		position: relative;
		display: block;
		border: 1px solid rgba(34,51,68,0.2);
	}
	
	.frame {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

#downloads {
	background: @bg_base;
	padding: (@baseline_px * 2) (@gutter_width * 2) 1px;

	.download-links {
		list-style: none;

		li {
			clear: left;
			display: inline-block;

			a {
				display: block;
				text-transform: uppercase;
				letter-spacing: 1px;
				border: 1px solid @secondary_base;
				padding: (@baseline_px / 2) (@gutter_width * 1.5);
				.border-radius(4px);
				.transition(all 0.5s @easeOutExpo);

				&:hover, &:active {
					background: @secondary_base;
					color: @bg_base;
				}
			}
		}
	}
}

#download-sharing {
	h2 { font-size: 18px; }
	margin-bottom: @baseline_px;
	overflow: hidden;
	height: 0;
}

@import "include/onmediaquery";
